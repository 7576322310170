import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export const Navbar = ({ onLogout }) => {
  const location = useLocation();

  return (
    <nav className="bg-white shadow-sm">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 justify-between">
          <div className="flex">
            <div className="flex flex-shrink-0 items-center">
              <img src="./logo-black.png" alt="Logo" className="h-8 w-auto" />
            </div>
            <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
              <Link
                to="/"
                className={`${
                  location.pathname === '/' ? 'border-slate-500' : 'border-transparent'
                } text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
              >
                Home
              </Link>
              <Link
                to="/subusers"
                className={`${
                  location.pathname === '/subusers' ? 'border-slate-500' : 'border-transparent'
                } text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
              >
                Subusers
              </Link>
              <Link
                to="/recharge"
                className={`${
                  location.pathname === '/recharge' ? 'border-slate-500' : 'border-transparent'
                } text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
              >
                Recharge Balance
              </Link>
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            <button
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
              onClick={onLogout}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
