import React, { useState } from 'react';
import { api } from './services/api';

const Recharge = () => {
  const [amount, setAmount] = useState(500); // default to 500GB
  const [paymentMethod, setPaymentMethod] = useState('Cryptocurrency'); // default to Cryptocurrency
  const pricePerGB = 0.01; // replace this with your actual price per GB

  const handleRecharge = async () => {
    try {
      const response = await api.get(`/reseller/recharge?amount=${amount}&payment_method=${paymentMethod}`);
      const { data } = response;
      if (data.status === 200) {
        window.location.href = data.data.link;
      } else {
        // handle error here
        console.error("Error recharging account");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">Recharge</h2>
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="amount">
          Amount (GB)
        </label>
        <select
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        >
          <option value="500">500GB</option>
          <option value="1000">1TB</option>
          <option value="2000">2TB</option>
          <option value="5000">5TB</option>
          <option value="10000">10TB</option>
        </select>
      </div>
      <div className="mt-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="payment-method">
          Payment Method
        </label>
        <select
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="payment-method"
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
        >
          <option value="Cryptocurrency">Cryptocurrency</option>
          <option value="Creditcard">Credit Card</option>
        </select>
      </div>
      <div className="mt-4">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
          onClick={handleRecharge}
        >
          Recharge (${(pricePerGB * amount).toFixed(2)})
        </button>
      </div>
    </div>
  );
};

export default Recharge;
