import React, { useState } from 'react';

const TableComponent = ({ data, usersPerPage, searchTerm, setSearchTerm }) => {

  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = data
    .filter((value) => {
      if (searchTerm === '') {
        return value;
      } else if (
        value.username.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return value;
      }
      return false;
    })
    .slice(indexOfFirstUser, indexOfLastUser);

  const pageNumbers = Math.ceil(data.length / usersPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="overflow-x-auto mt-6">
      <table className="table-auto border-collapse w-full">
        {/* Table Header */}
        <thead>
          <tr className="rounded-lg text-sm font-medium text-gray-700 text-left" style={{ fontSize: '0.9674rem' }}>
            <th className="px-4 py-2 bg-gray-200 " style={{ backgroundColor: '#f8f8f8' }}>Username</th>
            <th className="px-4 py-2 " style={{ backgroundColor: '#f8f8f8' }}>Balance</th>
            <th className="px-4 py-2 " style={{ backgroundColor: '#f8f8f8' }}>Proxy Auth Key</th>
            <th className="px-4 py-2 " style={{ backgroundColor: '#f8f8f8' }}>Created At</th>
            <th className="px-4 py-2 " style={{ backgroundColor: '#f8f8f8' }}>Updated At</th>
          </tr>
        </thead>
        {/* Table Body */}
        <tbody className="text-sm font-normal text-gray-700">
          {currentUsers.length === 0 ? (
            <tr>
              <td className="px-4 py-2 border" colSpan="5">
                No subusers found.
              </td>
            </tr>
          ) : (
            currentUsers.map((user) => (
              <tr key={user.username} className="hover:bg-gray-100 border-b border-gray-200 py-10">
                <td className="px-4 py-4">{user.username}</td>
                <td className="px-4 py-4">{user.balance}</td>
                <td className="px-4 py-4">{user.proxy_authkey}</td>
                <td className="px-4 py-4">{user.created_at}</td>
                <td className="px-4 py-4">{user.updated_at}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="mt-6">
        <ul className="flex list-none items-center justify-center">
          {Array.from({ length: pageNumbers }, (_, index) => index + 1).map((number) => (
            <li key={number}>
              <button
                onClick={() => paginate(number)}
                className="mx-2 px-3 py-2 rounded-md text-sm font-medium text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 focus:outline-none active:bg-gray-200"
              >
                {number}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TableComponent;
