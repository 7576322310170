import React, { useState, useEffect } from 'react';
import { api } from './services/api';

export default function Home() {
  const [info, setInfo] = useState(null);
  const [subUsers, setSubUsers] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const infoResponse = await api.get('/reseller/my_info');
        const subUsersResponse = await api.get('/reseller/sub_users/view_all');

        setInfo(infoResponse.data.data);
        setSubUsers(subUsersResponse.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="p-8 bg-gray-50 h-full min-h-screen">
      <h2 className="text-3xl font-semibold text-gray-700 mb-6">Dashboard</h2>
      <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-3">
        <div className="flex items-center px-5 py-6 shadow rounded-lg bg-white">
          <div className="mx-5">
            <h4 className="text-2xl font-semibold text-gray-700">{info?.username}</h4>
            <div className="text-gray-600">Username</div>
          </div>
        </div>
        <div className="flex items-center px-5 py-6 shadow rounded-lg bg-white">
          <div className="mx-5">
            <h4 className="text-2xl font-semibold text-gray-700">{info?.balance}</h4>
            <div className="text-gray-600">Balance</div>
          </div>
        </div>
        <div className="flex items-center px-5 py-6 shadow rounded-lg bg-white">
          <div className="mx-5">
            <h4 className="text-2xl font-semibold text-gray-700">{info?.apikey}</h4>
            <div className="text-gray-600">API Key</div>
          </div>
        </div>
        <div className="flex items-center px-5 py-6 shadow rounded-lg bg-white">
          <div className="mx-5">
            <h4 className="text-2xl font-semibold text-gray-700">{info?.created_at}</h4>
            <div className="text-gray-600">Created At</div>
          </div>
        </div>
        <div className="flex items-center px-5 py-6 shadow rounded-lg bg-white">
          <div className="mx-5">
            <h4 className="text-2xl font-semibold text-gray-700">{info?.updated_at}</h4>
            <div className="text-gray-600">Updated At</div>
          </div>
        </div>
        <div className="flex items-center px-5 py-6 shadow rounded-lg bg-white">
          <div className="mx-5">
            <h4 className="text-2xl font-semibold text-gray-700">{subUsers?.length}</h4>
            <div className="text-gray-600">Total Sub Users</div>
          </div>
        </div>
      </div>
    </div>
  );
}
