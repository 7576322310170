import React, { useState } from 'react';
import { api } from './services/api';

const PopupComponent = ({ fetchSubUsers, closePopup }) => {
  const [username, setUsername] = useState('');
  const [balance, setBalance] = useState(0);

  const handleCreateSubuser = async (e) => {
    e.preventDefault();
    try {
      await api.put('/reseller/sub_users/create', { username, balance: parseFloat(balance) });
      fetchSubUsers();
      closePopup();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40">
      <div className="bg-white rounded-lg shadow-lg p-6 mx-4 md:mx-0">
        <h3 className="text-xl font-semibold text-gray-700 mb-4">Create Subuser</h3>
        <form className="space-y-4" onSubmit={handleCreateSubuser}>
          <div>
            <label className="block text-sm font-medium text-gray-700" htmlFor="username">Username:</label>
            <input
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700" htmlFor="balance">Balance:</label>
            <input
              id="balance"
              type="number"
              value={balance}
              onChange={(e) => setBalance(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Submit
            </button>
            <button
              onClick={closePopup}
              className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PopupComponent;
