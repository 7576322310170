import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import Home from './Home';
import SubUsers from './SubUsers';
import Recharge from './Recharge';
import { Navbar } from './Navbar';

export const App = () => {
  const token = localStorage.getItem('token');

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.reload();
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <Router>
        {token && <Navbar onLogout={handleLogout} />}
        <div className="container mx-auto px-4 py-8">
          <div className="flex justify-center">
            <div className="w-full sm:w-11/12 md:w-10/12 lg:w-8/12">
              <Routes>
                <Route path="/subusers" element={token ? <SubUsers /> : <Login />} />
                <Route path="/" element={token ? <Home /> : <Login />} />
                <Route path="/recharge" element={token ? <Recharge /> : <Login />} />

                <Route path="*" element={token ? <Home /> : <Login />} />

              </Routes>
            </div>
          </div>
        </div>
      </Router>
    </div>
  );
};

export default App;
