import React, { useState } from 'react';
import { api } from './services/api';

export default function Login() {
  const [token, setToken] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      //set the token in the header
      api.defaults.headers['X-API-KEY'] = token;
      const response = await api.get('/reseller/my_info');

      if (response.status === 200) {
        localStorage.setItem('token', token);
        window.location.reload();
      } else {
        throw new Error('Invalid token or server error');
      }
    } catch (error) {
    
      setError("Your API key is invalid. Please try again.");
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="p-8 w-full max-w-lg bg-white rounded-lg shadow-md">
        <img alt="Logo" src="./logo-black.png"></img>
        <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Portal</h2>
        {error && <div className="mb-4 p-2 text-sm text-white bg-red-500 rounded">{error}</div>}
        <form onSubmit={handleSubmit}>
          <input
            className="w-full px-3 py-2 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-400 transition-colors"
            type="text"
            placeholder="Please enter your API key"
            value={token}
            onChange={(e) => setToken(e.target.value)}
          />
          <button
            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow w-full transition-colors"
            type="submit"
          >
            Save & Login
          </button>
        </form>
      </div>
    </div>
  );
}
