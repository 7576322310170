import React, { useState, useEffect } from 'react';
import { api } from './services/api';
import TableComponent from './TableComponent';
import PopupComponent from './PopupComponent';

const SubUsers = () => {
  const [subUsers, setSubUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);

  const fetchSubUsers = async () => {
    setLoading(true);
    try {
      const response = await api.get('/reseller/sub_users/view_all');
      setSubUsers(response.data.data || []);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubUsers();
  }, []);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-4 flex items-center">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
        <button
          onClick={openPopup}
          className="ml-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          Create Subuser
        </button>
      </div>
      <TableComponent data={subUsers} usersPerPage={5} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      {showPopup && <PopupComponent fetchSubUsers={fetchSubUsers} closePopup={closePopup} />}
    </div>
  );
};

export default SubUsers;
