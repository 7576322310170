import axios from 'axios';

export const api = axios.create({
  baseURL: 'https://api.earn.fm/v1/',
});

api.interceptors.request.use((config) => {

  const token = localStorage.getItem('token');
  if (token) {
    config.headers["X-API-KEY"] = token;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});


//if the response is 401, then the token is invalid, so we remove it
api.interceptors.response.use((response) => {
  return response;
}
  , (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  }
);

